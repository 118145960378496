import axios from 'axios'
import { useState, useEffect } from 'react'

export const useFetch = url => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    useEffect(() => {
        const fetchResources = async () => {
            await axios.get(url).then(res => {
                const status = parseInt(res.status)
                if (status === 200) {
                    setData(res.data.body.products)
                    setLoading(false)
                } else {
                    setError("Error inesperado!")
                    setLoading(false)
                }
            }).catch((error) => {
                setError(error)
                setLoading(false)
            })
        }
        fetchResources()
    }, [url])

    return { data, loading, error }
}
import PropTypes from "prop-types";
import React, { useEffect, Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect } from "react-redux";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

const HomePlants = lazy(() => import("./pages/home/HomePlants"));

// shop pages
const ShopGridStandard = lazy(() => import("./pages/shop/ShopGridStandard"));

// product pages
const Product = lazy(() => import("./pages/shop-product/Product"));

// other pages
const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/other/Contact"));

const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Checkout = lazy(() => import("./pages/other/Checkout"));

const NotFound = lazy(() => import("./pages/other/NotFound"));

const App = (props) => {
  useEffect(() => {
    props.dispatch(
      loadLanguages({
        languages: {
          en: require("./translations/english.json"),
          fn: require("./translations/french.json"),
          de: require("./translations/germany.json")
        }
      })
    );
  });

  return (
    <ToastProvider placement="bottom-left">
      <BreadcrumbsProvider>
        <Router>
          <ScrollToTop>
            <Suspense
              fallback={
                <div className="flone-preloader-wrapper">
                  <div className="flone-preloader">
                    <span></span>
                    <span></span>
                  </div>
                </div>
              }
            >
              <Switch>
                {/* mayorista */}
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/mayorista/catalogo/:brand"}
                  render={(routeProps) => (
                    <ShopGridStandard {...routeProps} brand={routeProps.match.params.brand} />
                  )}
                />
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/mayorista/catalogo"}
                  component={ShopGridStandard}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/mayorista/product/:id"}
                  render={(routeProps) => (
                    <Product {...routeProps} prodId={routeProps.match.params.id} key={routeProps.match.params.id} />
                  )}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/mayorista/about"}
                  component={About}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/mayorista/contact"}
                  component={Contact}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/mayorista/cart"}
                  component={Cart}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/mayorista/wishlist"}
                  component={Wishlist}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/mayorista/checkout"}
                  component={Checkout}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/mayorista/not-found"}
                  component={NotFound}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/mayorista"}
                  component={HomePlants}
                />

                {/* supermercado */}
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/super/catalogo"}
                  component={ShopGridStandard}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/super/product/:id"}
                  render={(routeProps) => (
                    <Product {...routeProps} prodId={routeProps.match.params.id} key={routeProps.match.params.id} />
                  )}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/super/about"}
                  component={About}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/super/contact"}
                  component={Contact}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/super/cart"}
                  component={Cart}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/super/wishlist"}
                  component={Wishlist}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/super/checkout"}
                  component={Checkout}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/super/not-found"}
                  component={NotFound}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/super"}
                  component={HomePlants}
                />

                {/* revendedor */}
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/revende/catalogo"}
                  component={ShopGridStandard}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/revende/product/:id"}
                  render={(routeProps) => (
                    <Product {...routeProps} prodId={routeProps.match.params.id} key={routeProps.match.params.id} />
                  )}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/revende/about"}
                  component={About}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/revende/contact"}
                  component={Contact}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/revende/cart"}
                  component={Cart}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/revende/wishlist"}
                  component={Wishlist}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/revende/checkout"}
                  component={Checkout}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/revende/not-found"}
                  component={NotFound}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/revende"}
                  component={HomePlants}
                />
                <Redirect

                  to={process.env.PUBLIC_URL + "/mayorista"}
                />
              </Switch>
            </Suspense>
          </ScrollToTop>
        </Router>
      </BreadcrumbsProvider>
    </ToastProvider>
  );
};

App.propTypes = {
  dispatch: PropTypes.func
};

export default connect()(multilanguage(App));

import React, { useEffect } from 'react'
import { useFetch } from './Hooks/useFetch'
import NodeServer from './api/NodeServer'
import { composeWithDevTools } from "redux-devtools-extension";
import { fetchProducts } from "./redux/actions/productActions";
import rootReducer from "./redux/reducers/rootReducer";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { save, load } from "redux-localstorage-simple";
import { Provider } from "react-redux";
import App from './App'
import { Spinner } from 'react-bootstrap'

const App2 = () => {
  const { data, error, loading } = useFetch(NodeServer.productsDir.sub.public)
  const { pathname } = window.location;
  const paths = pathname.split("/")

  const option = paths[1]

  const store = createStore(
    rootReducer,
    load(),
    composeWithDevTools(applyMiddleware(thunk, save()))
  );
  // fetch products from json file
  useEffect(() => {
    let finalProducts = data
    if (option === "super") {
      finalProducts = data.filter(producto => {
        const preciosSupermercado = producto.prices.find(
          precio => precio.type_price_name === "SUPERMERCADO" && precio.sell_price > 0
        );
        return preciosSupermercado !== undefined;
      });
    } else if (option === "revende") {
      finalProducts = data.filter(producto => {
        const preciosSupermercado = producto.prices.find(
          precio => precio.type_price_name === "REVENDEDOR" && precio.sell_price > 0
        );
        return preciosSupermercado !== undefined;
      });
    }

    if (!loading) {
      if (!error) {
        store.dispatch(fetchProducts(finalProducts));
      }
    }
    // eslint-disable-next-line
  }, [loading])

  if (!loading) {
    if (!error) {
      return (
        <Provider store={store}>
          <App />
        </Provider>
      )
    } else {
      return (
        <div>
          <span>{error}</span>
        </div>
      )
    }
  } else {
    return (
      <div className="container" style={{ textAlign: "center" }}>
        <Spinner animation="grow" role="status" variant="success">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    )
  }
};

export default App2;
